export const lan = {
  "password": "密码",
  "Sign-up": "注册",
  "Enter-your-email": "输入您的电子邮件",
  "Get-code": "获取验证码",
  "Email-verification-code": "电子邮件验证码",
  "Please-enter-a-password": "请输入密码",
  "Verify-password": "验证密码",
  "Please-enter-invitation-code": "请输入邀请码",
  "Registration": "注册",
  "Log-in-now": "立即登录",
  "Save-password": "保存密码",
  "Log-in": "登录",
  "Please-enter-your-valid-email": "请输入您的有效电子邮件",
  "Email-address": "电子邮件地址",
  "Change-login-password": "更改登录密码",
  "Change-payment-password": "更改付款密码",
  "Log-out": "退出",
  "Confirm": "确认",
  "Notice:-The-initial-password-is-123456": "注意：初始密码为123456",
  "Please-enter-your-previous-password": "请输入您以前的密码",
  "Please-confirm-your-new-password": "确认您的新密码",
  "Payment-password": "支付密码",
  "Two-passwords-do-not-match": "两个密码不匹配",
  "Intelligent_Trading": "智能交易",
  "Intelligent-Trading": "智能交易",
  "List": "列表",
  "Description": "描述",
  "Bulk-trade": "大宗交易",
  "Stock": "股票",
  "Application-List": "应用程序列表",
  "Recency": "最近",
  "Fluctuation-Rate": "波动率",
  "number": "数字",
  "Name": "名称",
  "Purchase-Price": "认购价格",
  "Details": "详情",
  "Buy": "购买",
  "AI-Smart-Trading": "AI智能交易",
  "IPO": "IPO",
  "Bulk-Trade": "大宗交易",
  "Intraday-Trading": "日内交易",
  "Watchlist": "观察名单",
  "Deposit": "存款",
  "Withdrawal": "提现",
  "Withdrawals": "提现",
  "KYC": "身份认证",
  "Transaction-History": "交易记录",
  "NSE-Top": "NSE排行",
  "BSE-Top": "BSE排行",
  "Find-stock-code": "查找股票代码",
  "Achieve": "实现",
  "profit-on-the-same-day": "当天盈利",
  "interpretation-of-popular-stocks-market-trend-answers": "热门股票解读，市场趋势答案",
  "Search": "搜索",
  "Search-History": "历史搜索",
  "BSE": "BSE",
  "NSE": "NSE",
  "Please-enter-your-full-name": "请输入您的全名",
  "Please-enter-your-pan-card-number": "请输入你的pan/aadhar卡号",
  "Verify-now": "立即验证",
  "Front-of-Pan-Card": "身份卡正面",
  "HOME": "主页",
  "MARKET": "市场",
  "NEWS": "新闻",
  "PORTFOLIO": "投资组合",
  "Portfolio": "投资组合",
  "PROFILE": "个人资料",
  "Profile": "简介",
  "SENSEX": "指数",
  "Total-Assets": "总资产增长",
  "sell-all-quantity": "全部卖出",
  "Setting": "设置",
  "setting": "设置",
  "TOTAL-ASSETS": "总资产",
  "Revenue-funds": "收入资金",
  "Funds-available": "可用资金",
  "Frozen-funds": "冻结资金",
  "Loan-Services": "贷款服务",
  "Customer-Services": "客户服务",
  "Banking-Details": "银行详细信息",
  "Payment-Method": "付款方式",
  "New-version-update": "版本更新",
  "Privacy-Agreement": "隐私协议",
  "About-Us": "关于我们",
  "Disclaimers": "免责声明",
  "Term-Of-Service": "服务条款",
  "Cooperation-Agreement": "合作协议",
  "Log-Out": "退出",
  "Avatar": "头像",
  "News": "新闻",
  "Market": "市场",
  "Economy": "经济",
  "Industry": "行业",
  "Bond": "债券",
  "Derivation": "衍生",
  "Enterprise": "企业",
  "Invest": "投资",
  "cancel": "取消",
  "confirm": "确认",
  "Language": "语言",
  "Basic-Certification": "初级认证",
  "Senior-Certification": "高级认证",
  "Please-select-the-certification-type": "请选择认证类型",
  "Certification-Type": "认证类型",
  "Please-select-the-type-of-document": "请选择证件类型",
  "ID-Card": "身份证",
  "Passport": "护照",
  "Drivers-license": "驾驶执照",
  "Document-Type": "证件类型",
  "Front-of-Card": "证件卡正面",
  "Back-of-Card": "证件卡反面",
  "Handheld-Card": "手持证件卡",
  "Please-proceed-with-basic-certification-first": "请先进行初级认证。",
  "kyc-notice1": "提交的身份证明文件必须清晰，具有手持面部识别功能和标准格式的照片。该系统采用“人员ID集成”，避免了用户身份信息被盗等人工干预。平台必须保证用户交易账户资金的安全。",
  "kyc-notice2": "请将图像裁剪到合适的大小！",
  "kyc-notice3": "1、照片大小在5M以内。2、确保不被安全软件拦截。3、同步手机时间，确保与当地时区匹配。4、不要戴眼镜或帽子。5、确保图像清晰可见。6、确保上传的数据图像与主要证明材料一致。",
  "Kind-reminder": "提示",
  "Under-review": "正在审核中",
  "KYC-Rejected": "被拒绝，请修改并重新提交",
  "Rejected": "未通过",
  "Passed": "已通过",
  "KYC-Passed": "已通过, 请进行高级认证",
  "Card-notice1": "照片大小在五M以内！",
  "Card-notice2": "照片必须为图片！",
  "Card-notice3": "上传身份证照片！",
  "Announcement-List": "公告列表",
  "no-more": "没有更多了",
  "Loan": "贷款",
  "Please-select-a-lenging-institution": "请选择贷款机构",
  "Please-enter-the-minimum-loan-amount": "请输入最低贷款金额",
  "Please-enter-the-maximum-loan-amount": "“请输入最高贷款金额",
  "Lending-Institution": "贷款机构",
  "Citibank": "花旗银行",
  "Add-new-bank": "添加新银行",
  "Please-enter-your-bank-name": "请输入您的银行名称",
  "Please-enter-your-bank-account-number": "请输入银行账号",
  "Please-enter-your-IFSC-code": "输入您的IFSC码",
  "Please-enter-your-payment-password": "请输入您的支付密码",
  "Submit": "提交",
  "Bank-Account-Details": "银行账户详细信息",
  "Standard-Disclaimers": "免责声明",
  "add-bank-disclaimer1": "为了确保您的账户的绝对安全和交易流程的准确性，您在填写银行详细信息时必须提供真实详细的信息。我们强调此事的严重性，因为所提供信息中的任何不准确之处都可能导致严重的财务损失，我们公司对此不承担任何责任。所有提现都将严格处理到以账户持有人名义持有的账户，确保交易的安全和合规性。",
  "add-bank-disclaimer2": "请认真对待这一步骤，因为填写这些信息的错误可能会导致极其严重的后果。我们公司对因所提供信息不准确而造成的损失不承担任何责任。为了维护您的利益，我们强烈建议在提交您的银行详细信息之前进行彻底的审查和确认。如果您有任何疑问或疑虑，请立即联系我们的客户服务团队寻求帮助。这项措施是为了为您的财务状况提供最大的安全保障，我们感谢您的理解和合作。请放心，我们致力于确保对您的资金和个人信息提供最高级别的保护。",
  "avatar-notice": "照片大小在1M以内！",
  "Check-changes": "检查更改",
  "Holding": "持有",
  // "Closed": "已关闭",
  "Subscribe": "订阅",
  "Please-enter-withdrawal-account": "请输入提现账户",
  "Please-enter-withdrawal-amount": "输入提现金额",
  "Please-enter-password": "输入密码",
  "Please-add-a-bank-account": "请添加银行账户",
  "Available-Funds": "可用资金",
  // "Submit": "提交",
  "Info": "提示",
  "note1": "1、目前有仓位订单，因此无法提现",
  "note2": "2、提现请求在被转移到交易账户之前被自动引导到先前通过实名认证验证的银行账户。银行账户持有人必须与交易账户持有人匹配，不允许向他人持有的账户转账。公司对任何资金损失不承担任何责任。",
  "note3": "3、股票交易日的提现时间为上午9:30至11:30以及下午1:00至3:00。",
  "note4": "4、每次提现的最低金额为2000 INR",
  "note5": "3、在提现期间，提现通常在2小时内到达，提现时间因银行间结算时间而异。每个银行的到达时间不同，最晚到达时间是第二天24:00之前的t+1。",
  "Expected-receipt": "预计收到",
  "The-commission-is": "手续费为",
  "The-minimum-withdrawal": "最低提款额为",
  "The-maximum-withdrawal": "最高提款额为",
  "Exceeding-available-funds": "超出可用资金",
  "Withdrawal-records": "提现记录",
  "Amount": "数量",
  "Status": "状态",
  "Received": "到账",
  "Charges": "手续费",
  "Time": "时间",
  "Login": "登录",
  "Financial-products": "理财产品",
  // "Deposit": "充值",
  "Deposit-records": "充值记录",
  "Funds-under-custody": "正在托管的资金",
  "Minimum-Investment": "最低投资额",
  "Daily-Return": "日收益率",
  "Duration": "周期",
  "day": "天",
  "Expected-Profit": "预期利润",
  "Total-Revenue": "总收入",
  "Orders": "订单",
  "Please-enter-the-purchase-amount": "请输入认购金额",
  "Please-enter-the-correct-amount": "请输入正确的金额",
  "The-maximum-purchase-amount-is": "最高认购额为",
  "The-minimum-purchase-amount-is": "最低认购额为",
  "Insufficient-funds-in-custody": "可用资金不足",
  "Purchase-records": "认购记录",
  "Add-items-of-interest": "加入收藏",
  "Volume(Shares)": "数量(股份)",
  "Market-cap": "市值",
  "Turnover": "营业额",
  "Face-Value": "面值",
  "Latest-News": "最新消息",
  "Symbol": "股票简称",
  "Summary": "总结",
  "Issue": "问题",
  "Minute": "分",
  "Day": "天",
  "Month": "月",
  "Year": "年",
  "Sell": "卖出",
  "52-WK-High": "52周最高价",
  "All-Time-High": "历史最高价",
  "52-WK-High-Date": "52周最高日",
  "All-Time-High-Date": "历史最高日",
  "52-WK-Low": "52周最低价",
  "All-Time-Low": "历史最低价",
  "52-WK-Low-Date": "52周最低日",
  "All-Time-Low-Date": "历史最低日",
  "Company-Overview": "公司概况",
  "see-more": "更多",
  "Ratio-Performance": "比率性能",
  "Stock-Performance": "股票表现",
  "Peer-Group-Comparison": "对等组比较",
  "nodata": "暂无数据",
  "Market-Cap": "市场价值",
  "EPS-TTM": "盈率TTM",
  "P/E-Ratio": "市盈率",
  "Latest-Dividend": "股息%",
  "Latest-Dividend-Date": "股息日期",
  "Dividend-Yield": "股息收益率",
  "Book-Value-Share": "账面净值",
  "P/B-Ratio": "市净率%",
  "Top-Gainers": "涨幅居前",
  "Top-Losers": "跌幅居前",
  // "Info": "资讯",
  "Please-enter-your-email": "请输入您的邮箱",
  "QIP": "战略配售",
  "FTC": "FTC",
  "Block-Deal": "大宗交易",
  "Margin-Trading": "融资融券",
  "Discounted-price": "折后价",
  "Please-enter-stock-code": "请输入股票代码",
  "Quantity": "数量",
  "Unit": "股",
  "Payable-Amount": "应付金额",
  "Check": "核查",
  "come-on-in": "输入",
  "payment": "付款",
  "amount": "数量",
  "Would-you-like-to-purchase-it": "您确定要购买吗",
  "Leverage-ratio": "杠杆率",
  "No": "No.",
  "buy-stocks": "买入股票",
  "sell-stocks": "卖出股票",
  "Total-investment": "总投资",
  "Total-PL": "总益损",
  "All": "所有",
  "LTP": "LTP", //上次交易价格
  "Do-you-really-want-to-sell-all-quantity": "您确定要卖出所有数量吗?",
  "Delete-item-of-interest": "移除收藏",
  "price": "价格",
  "Net-Qty": "净数量",
  "Fee": "服务费",
  "P&L": "损益",
  "buy-value": "买入价格",
  "Minimum-quantity": "最低数量",
  "Discount": "折扣",
  "discount": "折扣",
  "Lock": "锁定期",
  "Order-List": "订单列表",
  "Unaudited": "未审核",
  "Briefly": "收起",
  "login-notice": "您还未登录, 请先登录",
  "to-login": "去登录",
  "Normal": "普通",
  "Direaction": "购买方向",
  "Buy-more": "买多",
  "Short-selling": "卖空",
  "Money": "金额",
  "Please-enter-the-money": "请输入金额",
  "Ordinary": "普通",
  "Type": "类型",
  "Stamp-duty": "印花税",
  "Residue": "剩余",
  "Earnings": "收益",
  "Upcoming": "未开放",
  "Open": "开放中",
  "Closed": "已结束",
  "Exchange": "交易所",
  "Listed": "已上市",
  "Number": "数量",
  "Price": "价格",
  "List-Date": "上市时间",
  "Issue-price": "发行价格", //发行价
  "Total-Issue-Size": "发行股数",
  "Subscribed-shares": "认购股数",
  "Top-grid-subscription": "顶格申购",
  "YES": "是",
  "IPO-Open-Date": "IPO上市时间",
  "IPO-Close-Date": "IPO结束时间",
  "NO": "否",
  "Open1": "开",
  "Close": "关",
  "To-be-subscribed": "去认购",
  "Failed": "未中签",
  "Subscribed": "已认购",
  "Frequency": "申购次数",
  "Award-number": "中签股数",
  "Subscription": "认购",
  "Do-you-really-want-to-subscribe-this-stock": "您真的要认购这只股票吗?",
  "Please-enter-your-email-verification-code": "请输入您的电子邮件验证码",
  "Select-Bank": "选择银行",
  "Please-select-a-bank": "请选择银行",
  "Deposit-tips": "该银行今日机构账户预约金额已满请联系客服充值",
  "Contact-customer-service": "联系客服",
  "HDFC-Bank": "HDFC Bank",
  "ICICI-Bank": "ICICI Bank",
  "SBI": "SBI",
  "Kotak-Mahindra": "Kotak Mahindra",
  "Axis-Bank": "Axis Bank",
  "IndusInd-Bank": "IndusInd Bank",
  "Bank-of-Baroda": "Bank of Baroda",
  "Punjab-National-Bank": "Punjab National Bank",
  "Union-Bank-of-India": "Union Bank of India",
  "Canara-Bank": "Canara Bank",
  "Manual-Closed": "手动平仓",
  "Forced-Closed": "强制平仓",
  "Download-software": "下载",
  "Are-you-sure-to-delete-it": "确定删除吗?",
  "Bank-account": "银行账户",
  "Current-Market-Price": "当前市价",
  "Products-introduce": "产品介绍: ",
  "Products-tips": "例如：选择购买15天私募基金，期限为15天，购买金额为100万卢比，日回报率为0.2%，每天可获得2000卢比，15天的总回报为30000卢比，收益每日结算，每日收益的结算可随时存入，购买本金到期后，将自动转入您的基金账户。",
  "Country": "国家",
  "Currency": "货币",
  "High": "最高价",
  "Low": "最低价",
  "Volume": "成交量",
  "Limit-up": "涨停",
  "Sector": "部门",
  "Employees": "员工人数",
  "Website": "网站",
  "CEO": "总裁",
  "Address": "地址",
  "City": "城市",
  "Zip": "邮编",
  "State": "状态",
  "Phone": "电话号码",
  "financial-confirm": "确定要购买吗?",
  "Please-enter-the-exchange-amount": "请输入兑换金额?",
  "Exchange-Rate": "兑换率",
  "Get": "可得",
  "Charges-Rate": "手续费比例",
  "Please-select-a-different-currency": "请选择不同的货币",
  "Currency-Exchange": "货币兑换",
  "exchange-notice": "确定要兑换吗?",
  "pay-password-notice": "请设置支付密码!",
  "Go-set-up": "去设置",

  "aboutUs1": "（MQG）成立于2010年，是一家总部位于英国伦敦的私募股权公司，由其母公司Brookfield MQG Holdings Limited（前身为（MQG）集团有限公司）全资拥有，由英国金融行为监管局授权和监管，注册号：OC363917，由迪拜金融服务管理局监管。该公司目前投资于能源、石油和天然气、教育、农业、航空、采矿，以及不良债务、公司债务、资产管理、可转换证券、房地产以及亚洲和新兴市场股票。我们还拥有一个全球融资网络，包括主权财富基金、私募股权公司、对冲基金、金融机构、保险公司、投资银行、养老基金、家族理财办公室和超高净值个人，我们可以帮助您满足融资需求。",
  "aboutUs2": "（MQG）管理公司成立于1995年4月，总部位于加利福尼亚州洛杉矶。2009年，MQG与其他八家公司（包括：贝莱德、景顺、安联伯恩斯坦等）一起被美国财政部选中，参与了政府的公共私人投资计划（PPIP），2011年12月31日，MQG PPIP基金，L.P.。2012年4月12日，（MQG）首次公开募股在纽约证券交易所上市，纽约证券交易所代码：OAK-A。",
  "aboutUs3": "截至2023年12月31日。我们为全球领先的有限合伙人和主权财富基金管理着超过1800亿美元的资产。我们通过投资由强大的管理团队和创始人支持的公司，帮助建立和培养国家和地区冠军。在我们29年的新兴市场投资历史中，我们已向亚洲、非洲和中东的100多家公司投资了超过65亿美元。截至2023年底，（MQG）在纽约、斯坦福（康涅狄格州）、洛杉矶、休斯顿、伦敦、英国、马德里、西班牙、瑞典、孟买和印度都有投资。我们在香港、北京、上海、东京、巴黎、都柏林、阿姆斯特丹、卢森堡、法兰克福、新加坡、首尔和迪拜设有办事处。并以诱人的回报率向投资者返还了70多亿美元的现金收益。我们的投资理念专注于选择性发起、严格的交易结构和密集的投资组合管理，帮助我们建立了卓越的业绩记录，回报率远高于摩根士丹利资本国际新兴市场指数。",
  "aboutUs4": "MQG的投资者包括世界前十大主权财富基金中的六家，美国100个养老金计划中的70家，以及350多家公司、300多家大学和慈善捐赠基金会。（MQG）自成立以来，年化总回报率达到23%，年化净回报率达到17%，投资回报率非常快。（MQG）联合创始人霍华德·马克斯是《投资中最重要的事情》一书的作者，沃伦·巴菲特将其列为投资必读书目。",
  "aboutUs5": "值得注意的是，2019年，布鲁克菲尔德资产管理公司收购了（MQG）的多数股权。Brookfield和MQG Capital共同为投资者提供了当今最全面的另类投资产品之一。MQG Capital在合作利用彼此优势的同时，作为布鲁克菲尔德家族内的一家独立企业运营，拥有自己的产品供应以及投资、营销和支持团队。2019年12月16日，布鲁克菲尔德从信实工业投资控股公司（Reliance Industrial Investments and Holdings，简称“RIIHL”）宣布提供金融服务。RIIHL该公司拥有银行以外公司的证券，并提供投资服务。2021年3月31日，GREENOAK印度投资顾问私人有限公司成立。SEBI注册号：INA000015817，成为印度首家私募股权机构。",


  "tos1": "服务条款：",
  "tos2": "本交易所致力于股票，贵金属，期货，理财，打新，质押等产品的交易及相关服务的提供（以下简称“本服务”）。为方便本协议中的措辞，本网站在本协议中称为“我们”或第一人称代词的其他适用形式。凡登录本网站的自然人或其他主体，均为本网站的用户。本协议中为表述方便起见，将用户称为“您”或第二人称代词的任何其他适用形式。为方便本协议中的措辞，您和我们合称为“双方”，单独称为“一方”。目前为止，我们可以提供150多个国家和地区的投资和交易服务。",
  "tos3": "重要提醒：",
  "tos4": "我们在此提醒您：",
  "tos5": "• 1. 股票，贵金属，期货，理财，质押等产品本身并非由交易所注册地的金融机构、政府或本交易所提供；",
  "tos6": "• 2.股票，贵金属，期货，理财，质押等产品交易市场是一个全新的投资领域、不一定会有稳定的投资市场空间和前景；",
  "tos7": "• 3. 股票，贵金属，期货，理财，质押等产品的资产主要被投机者使用，零售和商业市场使用相对较少，现在正处于开拓和衍生阶段；股票，贵金属，期货，理财，质押等产品的资产交易具有高风险，因为它们全天交易，市场价格不受时间限制，做市商和全球政府政策可能会导致其价格出现较大波动；",
  "tos8": "• 4. 本公司可随时暂停或终止您的帐户或服务的使用，或任何数字资产交易的处理，如果其自行决定您已违反本协议或其提供或您使用您所在司法管辖区的服务是非法的。禁止位于中国，巴基斯坦，古巴、伊朗、朝鲜、苏丹、叙利亚、委内瑞拉和克里米亚的人员使用本服务。此外，以色列、伊拉克、孟加拉国、玻利维亚、厄瓜多尔、吉尔吉斯斯坦、塞瓦斯托波尔的任何人都被禁止使用本网站提供的衍生品交易服务。上述国家和地区列表可能会根据政策和产品类型的变化而变化。部分国家和地区因为金融系统对接完善问题，只能使用二级交易所的形式进入市场，我们可能不会特别通知您此类情况下的变化。请注意本协议的任何更新。",
  "tos9": "股票，贵金属，期货，理财，质押等产品投资交易具有高风险，因此不适合绝大多数人。您承认并理解投资可能会导致您的投资部分或全部损失，因此建议您根据自己的损失承受能力决定投资金额。您承认并理解期货资产可能会产生衍生风险。因此，如果您有任何疑问，建议您先寻求财务顾问的帮助。此外，除了上述风险外，还可能存在不可预测的风险。因此，建议您在做出任何买卖和投资期货的决定之前，仔细考虑并使用清晰的判断来评估您的财务状况和上述风险；",
  "tos10": "特此通知您：",
  "tos11": "• 1. 您理解本网站仅作为您获取股票，贵金属，期货，理财，质押等产品信息、寻找交易对手、进行股票，贵金属，期货，理财，质押等产品谈判和进行交易的场所。本网站不参与您的任何交易，因此您应自行谨慎评估相关数字资产和/或信息的真实性、合法性和有效性，并自行承担由此产生的责任和损失。",
  "tos12": "• 2. 本网站上的所有意见、信息、讨论、分析、价格、建议和其他信息均为一般市场评论，不构成任何投资建议。我们不承担因依赖上述信息而直接或间接产生的任何损失，包括但不限于任何利润损失。",
  "tos13": "• 3. 我们保留随时自行决定、修改或修改本网站任何内容的权利。本协议“上次更新时间”部分显示的日期和时间是指对此处包含的条款和本网页内容进行任何更改的时间。我们已采取合理措施确保本网站信息的准确性；但是，我们不保证此类准确性的程度，也不承担因本网站信息直接或间接或因未能与互联网连接、传输或接收任何通知和信息而导致的任何延迟或失败的损失",
  "tos14": "• 4. 使用基于互联网的交易系统也存在风险，包括但不限于软件、硬件或互联网链接的故障等。鉴于我们无法控制互联网的可靠性和可用性，我们不会对任何失真、延迟和链接故障负责。",
  "tos15": "• 5. 禁止利用本网站从事任何非法交易活动或非法活动，如洗钱、走私和商业贿赂。如发现涉嫌违法交易活动或不正当活动，本网站将采取一切可行措施，包括但不限于冻结违规者账户、通知有关部门等，由此产生的任何责任我们不承担任何责任。并保留追究相关人员责任的权利；",
  "tos16": "• 6. 禁止以恶意操纵市场、不当交易或任何其他非法交易活动为目的使用本网站。如发现此类非法交易活动，本网站将采取警告、限制交易、关闭账户等预防和保护措施，防止此类恶意操纵价格、恶意影响交易系统等违法行为；我们不承担由此产生的任何责任，并保留追究相关人员责任的权利。",
  "tos17": "一、总则",
  "tos18": "• 1.1 用户协议（以下简称“本协议”或“本条款和条件”）由正文、隐私条款、了解您的客户和反洗钱政策以及任何规则、声明组成本网站已发布或将来可能发布的、说明等。",
  "tos19": "• 1.2 在使用本网站提供的服务之前，您应仔细阅读本协议，如有疑问或有其他必要，请咨询专业律师。如果您不同意本协议的条款和条件和/或对其不时和任何时间所做的任何更改，请立即停止使用本网站提供的服务或停止登录本网站。当您登录本网站或使用本网站提供的任何服务或从事任何其他类似活动时，即视为您已理解并完全同意本协议的所有条款和条件，包括任何及所有变更、修改或变更本网站可能会不时和随时对本协议作出规定。",
  "tos20": "• 1.3 按照本网站要求填写相关信息并办理其他相关手续后，您将成功注册成为本网站会员（以下简称“会员”）；在注册过程中，如果您点击“我同意”，则视为您已通过电子签名方式与本公司达成协议；或您在使用本网站时，点击了“我同意”按钮或类似按钮，或者您以本网站允许的任何方式使用本网站提供的服务，即视为您已完全理解，同意并接受本协议下的所有条款和条件，在这种情况下，",
  "tos21": "• 1.4 您成为本网站会员后，您将收到一个会员帐号和相应的密码，作为本网站会员，您应妥善保管；会员应对通过其账户进行的所有活动和事件负责。",
  "tos22": "• 1.5 您不得在本网站提供的数字资产交易平台上进行交易，不得使用本网站规定的会员专属服务，除非您成为本网站会员; 如果您不是本网站的会员，您只能登录和浏览本网站，并在本网站的规则和规定允许的范围内使用其他服务。",
  "tos23": "• 1.6 您在注册成为本网站会员并使用本网站提供的任何服务和功能时，即视为您已阅读、理解本协议，并且：",
  "tos24": "• 1.6.1 接受本协议所有条款和条件的约束；",
  "tos25": "• 1.6.2 您确认您已年满 18岁，或其他适用法律要求的订立合同的法定年龄，并且您在本网站注册、通过本网站购买或销售、发布有关本网站的信息网站及其他表明您接受本网站提供的服务的行为应遵守对您有管辖权的主权国家或地区的相关法律法规，并且您确认您有足够的能力接受这些条款和条件，进入进入交易并使用本网站进行网上虚拟资产交易。",
  "tos26": "• 1.6.3 您承诺您在本协议项下交易中涉及的所有数字资产均由您合法获得并拥有。",
  "tos27": "• 1.6.4 您同意为您自己的交易和非交易活动以及由此产生的任何和所有盈亏承担任何和所有责任。",
  "tos28": "• 1.6.5 您确认注册时提供的信息真实准确。",
  "tos29": "• 1.6.6 您同意遵守任何和所有相关法律，包括出于税收目的报告任何交易利润。",
  "tos30": "• 1.6.7 您同意始终不从事或参与任何损害本网站或本公司利益的行为或活动，无论是否与本网站提供的服务有关。",
  "tos31": "• 1.6.8 本协议仅对您与我们之间的权利和义务具有约束力，不涉及本网站用户之间以及其他网站与我们之间因数字资产交易而产生或与之相关的法律关系和法律纠纷。你。",
  "tos32": "二、本协议的修改",
  "tos33": "我们保留不时修改本协议的权利，并通过在网站上公告的方式披露此类修改，而无需就您的权利向您发送单独的通知，此类修改的时间将显示在上面的“上次更新本协议的”部分。修改日期将在修改后的协议的第一页上注明。修改后的协议将在网站上公布后立即生效。您应不时浏览本网站，并关注对本协议进行修改的时间和内容（如有）。如果您不同意修改内容，您应立即停止使用本网站提供的服务；如果您继续使用本网站提供的服务，",
  "tos34": "3.注册",
  "tos35": "• 3.1 注册资格 您确认并承诺：您应是适用法律规定的具有签署本协议和使用本网站服务能力的自然人、法人或其他组织，当您完成注册过程或当您以本网站允许的任何其他方式使用本网站提供的服务时。点击表示您同意注册的按钮后，即视为您本人或您的授权代理人同意本协议的内容，您的授权代理人将代表您在本网站注册并使用本网站提供的服务. 如果您不是具有上述能力的自然人、法人或组织，",
  "tos36": "• 3.2 注册目的您确认并承诺，您在本网站注册不会以违反任何适用法律法规或破坏本网站资产交易秩序为目的。",
  "tos37": "• 3.3 注册流程",
  "tos38": "• 3.3.1 您同意按照本网站用户注册页面的要求提供有效的电子邮箱、手机号码等信息。您可以使用电子邮件地址、手机号码或本网站允许的任何其他方式登录本网站。必要时，根据相关司法管辖区适用法律法规的要求，您应提供真实姓名、身份证等适用法律法规、隐私条款和反洗钱条款要求的信息，并不断更新您的注册数据，使其按要求及时、详细和准确。所有原始类型的数据将被引用为注册信息。您应对真实性负责，",
  "tos39": "• 3.3.2 如果您所在主权国家或地区的任何适用法律、法规、规章、命令和其他规范性文件要求手机账户必须实名，您在此确认手机您提供的注册号已经完成实名注册。如您不能按要求提供手机号码，由此给您带来的任何直接或间接损失及不利后果由您自行承担。",
  "tos40": "• 3.3.3 在您以合法、完整、有效的方式提供所需的注册信息并通过相关审核后，您将有权获得本网站的账号和密码。获得该帐号和密码后，即视为您注册成功，您可以以会员身份登录本网站。",
  "tos41": "• 3.3.4 您同意接收本网站发送的与其管理和运营相关的电子邮件和/或短信。",
  "tos42": "4.服务",
  "tos43": "本网站仅为您通过本网站从股票，贵金属，期货，理财，质押等产品交易活动（包括但不限于此类资产交易等）提供在线交易平台服务。本网站不作为买方或卖方参与数字资产的交易；本网站仅能提供部分国家法定货币的充值和提现服务，方便投资者参与其中。",
  "tos44": "• 4.1 服务内容",
  "tos45": "• 4.1.1 您有权在本网站浏览股票，贵金属，期货，理财，质押等产品的实时报价和交易信息，有权通过本网站提交股票，贵金属，期货，理财，质押等产品交易指令并完成数字资产交易。",
  "tos46": "• 4.1.2 您有权查看本网站会员账户下的信息，并有权使用本网站提供的功能。",
  "tos47": "• 4.1.3 您有权按照本网站发布的活动规则参与本网站组织的网站活动。",
  "tos48": "• 4.1.4 本网站承诺为您提供的其他服务。",
  "tos49": "• 4.2 服务规则 您承诺遵守本网站的以下服务规则：",
  "tos50": "• 4.2.1 您应遵守适用的法律、法规、规章和政策要求的规定，确保您账户中所有资产来源的合法性，不得从事任何非法活动或其他有损您的行为的活动。损害本网站或任何第三方的权益，例如发送或接收非法、非法或侵犯他人权益的信息，发送或接收传销信息或造成其他危害的信息或言论，未经授权使用或伪造本网站的电子邮件标题信息等。",
  "tos51": "• 4.2.2 您应遵守适用的法律法规，妥善使用和保管您在本网站的账户和登录密码、您的金融交易密码以及您在注册账户时提供的与账户绑定的手机号码，以及通过您的手机收到的验证码的安全性。您对您使用本网站账户和登录密码、金融交易密码、发送到您手机的验证码进行的任何和所有操作，以及此类操作的所有后果承担全部责任。当您发现您在本网站的帐号、登录密码、金融交易密码或手机验证码被任何未经授权的第三方使用时，如发现其他与您的账户安全有关的问题，您应及时有效地通知本网站，并要求本网站暂停您在本网站的账户服务。本网站有权在合理时间内对您的请求采取行动；尽管如此，本网站对在采取该等行动之前已经产生的后果不承担任何责任，包括但不限于您可能遭受的任何损失。未经本网站同意，您不得以捐赠、出借、出租、转让或其他方式将您在本网站的账户转让给任何其他人。本网站有权在合理时间内对您的请求采取行动；尽管如此，本网站对在采取该行动之前已经产生的后果不承担任何责任，包括但不限于您可能遭受的任何损失。未经本网站同意，您不得以捐赠、出借、出租、转让或其他方式将您在本网站的账户转让给任何其他人。本网站有权在合理时间内对您的请求采取行动；尽管如此，本网站对在采取该行动之前已经产生的后果不承担任何责任，包括但不限于您可能遭受的任何损失。未经本网站同意，您不得以捐赠、出借、出租、转让或其他方式将您在本网站的账户转让给任何其他人。",
  "tos52": "• 4.2.3 您同意对您使用您的帐号和密码进行的所有活动（包括但不限于信息披露、信息发布、在线点击批准或提交各种规则协议、在线续订协议或购买服务）承担责任。本网站。",
  "tos53": "• 4.2.4 您在本网站进行股票，贵金属，期货，理财，质押等产品交易时，不得恶意干扰股票，贵金属，期货，理财，质押等产品交易的正常进行或扰乱交易秩序；您不得以任何技术手段或其他方式干扰本网站的正常运行或干扰其他用户对服务的使用；您不得以虚假事实为由恶意诋毁本网站的商业信誉。",
  "tos54": "• 4.2.5 如果您与任何其他用户就网上交易发生任何争议，您不得通过司法或政府以外的任何方式要求本网站提供相关信息。",
  "tos55": "• 4.2.6 您在使用本网站提供的服务过程中产生的一切应交税费以及与硬件、软件和服务相关的一切费用，均由您自行承担。",
  "tos56": "• 4.2.7 您应遵守本协议及本网站不时发布的其他服务条款和操作规则，您有权随时终止使用本网站提供的服务。",
  "tos57": "• 4.3 产品规则",
  "tos58": "• 4.3.1 交易产品规则 您承诺在登录本网站并通过本网站与其他用户进行交易的过程中，您将妥善遵守以下交易规则。",
  "tos59": "• 4.3.1.1 浏览交易信息 您在本网站浏览交易信息时，应仔细阅读交易信息中的所有内容，包括但不限于价格、委托、手续费、买入或卖出方向，您应当接受交易信息中包含的所有内容后，您可以点击按钮进行交易。",
  "tos60": "• 4.3.1.2 提交佣金 在浏览并验证交易信息后，您可以提交您的交易佣金。您提交交易委托后，即视为您授权本网站代理您进行相应的交易，当有符合您报价的交易建议时，本网站将自动完成撮合操作，恕不另行通知.",
  "tos61": "• 4.3.1.3 查阅交易明细 您可以通过管理中心在交易报表中查看相应的交易记录，确认自己的明细交易记录。",
  "tos62": "• 4.3.1.4 撤销/修改交易佣金。您有权在交易结束前随时撤销或修改您的交易佣金。",
  "tos63": "五、本网站的权利和义务",
  "tos64": "• 5.1 如果您不具备本协议约定的注册资格，本网站有权拒绝您注册；如果您已经注册，本网站有权撤销您的会员账户，本网站保留追究您或您的授权代理人责任的权利。此外，本网站保留在任何其他情况下决定是否接受您的注册申请的权利。",
  "tos65": "• 5.2 当本网站自行判断您或您的关联账户用户不适合进行高风险投资时，本网站有权暂停或终止您的账户及其所有关联账户的使用。",
  "tos66": "• 5.3 本网站发现账户的用户不是该账户的初始注册人时，有权暂停或终止该用户对该账户的访问。",
  "tos67": "• 5.4 本网站通过技术检测、人工抽样等方式合理怀疑您提供的信息错误、不真实、无效或不完整的，本网站有权通知您更正或更新信息，或暂停或终止向您提供服务。",
  "tos68": "• 5.5 本网站有权在发现本网站上显示的任何信息存在明显错误时进行更正。",
  "tos69": "• 5.6 本网站保留随时修改、暂停或终止本网站提供的服务的权利，并有权在不事先通知您的情况下修改或暂停服务；如果本网站终止本网站提供的一项或多项服务，则本网站的此类终止将于本网站公告终止之日生效。",
  "tos70": "• 5.7 本网站应采取必要的技术手段和管理措施保障本网站的正常运行，提供必要、可靠的交易环境和交易服务，维护股票，贵金属，期货，理财，质押等产品交易秩序。",
  "tos71": "• 5.8 如果您连续一年未使用您的会员账号和密码登录本网站，本网站有权撤销您的账户。您的账户被撤销后，本网站有权将该账户所代表的会员名称提供给其他会员申请者。",
  "tos72": "• 5.9 本网站通过加强技术投入、加强安全防范等方式保障您的数字资产安全，并有义务提前通知您账户中可预见的安全风险。",
  "tos73": "• 5.10 本网站有权随时删除不符合法律法规或本网站规则的各类内容和信息，本网站行使该权利无需事先通知你。",
  "tos74": "• 5.11 本网站有权根据您所在主权国家或地区的适用法律、行政法规、规章、命令和其他规范性文件，要求您提供更多信息或数据，并采取符合当地标准要求的合理措施，您有义务为此类措施提供适当的协助；本网站有权暂停或永久终止您访问本网站以及本网站提供的部分或全部服务。",
  "tos75": "• 5.12 本网站保留自行决定关闭您账户的权利，提前七（7）个工作日通知您。收到此类通知后，您将有七 (7) 个工作日来取消您的订单并关闭您的头寸。如果您在此期限结束前不这样做，我们可能会被迫取消您的订单并关闭您的头寸，并将您账户中的贵金属，期货，部分数字货币退还给您。",
  "tos76": "• 5.13 为保护用户的权益，本网站有权在特殊情况下（如系统故障、网络故障、极端市场条件等）对网上提供的产品进行调整，如提前交付和结算合同。此类提前交割的合约类型、合约期限和结算价格以本网站公告为准。",
  "tos77": "6.赔偿",
  "tos78": "• 6.1 在任何情况下，我们对您直接损害的责任不会超过您使用本网站提供的服务三（3）个月所产生的总费用。",
  "tos79": "• 6.2 如果您违反本协议或任何适用的法律或行政法规，您应向我们支付至少200 万美元的赔偿并承担与该违约有关的所有费用（包括律师费等）。如该赔偿不能弥补实际损失，您应补足差额。",
  "tos80": "7. 禁令救济权",
  "tos81": "您和我们都承认普通法对违反协议或可能违反合同的补救措施可能不足以弥补我们遭受的所有损失；因此，在发生违约或可能违约的情况下，守约方有权寻求禁令救济以及普通法或衡平法允许的所有其他补救措施。",
  "tos82": "八、责任的限制与免除",
  "tos83": "• 8.1 您理解并同意，在任何情况下，我们均不对以下任何事件负责：",
  "tos84": "• 8.1.1 收入损失；",
  "tos85": "• 8.1.2 交易利润损失或合同损失；",
  "tos86": "• 8.1.3 业务中断",
  "tos87": "• 8.1.4 预期货币损失的损失",
  "tos88": "• 8.1.5 信息丢失",
  "tos89": "• 8.1.6 机会丧失、商誉或声誉受损",
  "tos90": "• 8.1.7 数据损坏或丢失；",
  "tos91": "• 8.1.8 购买替代产品或服务的成本；",
  "tos92": "• 8.1.9 因任何侵权（包括疏忽）、违约或任何其他原因而导致的任何间接、特殊或附带损失或损害，无论此类损失或损害我们是否可以合理预见，也无论或者我们是否会被提前通知此类损失或损坏的可能性。",
  "tos93": "• 8.1.10 8.1.1 至 8.1.9 项相互独立。",
  "tos94": "• 8.2 您理解并同意，我们不对因下列任何事件造成的任何损害承担责任：",
  "tos95": "• 8.2.1 我们有正当理由相信您的具体交易可能涉及严重违法或违反法律或协议的；",
  "tos96": "• 8.2.2 我们有合理理由相信您在本网站上的行为涉嫌违法或不道德；",
  "tos97": "• 8.2.3 通过本网站提供的服务购买或获取任何数据、信息或交易等产生的费用和损失；",
  "tos98": "• 8.2.4 您对本网站提供的服务的误解；",
  "tos99": "• 8.2.5 与本网站提供的服务有关的任何其他不能归咎于我们的损失。",
  "tos100": "• 8.3 由于信息网络设备维护、信息网络连接故障、计算机、通信或其他系统故障、电力故障、天气状况、意外事故、劳资行为、劳资纠纷，我们无法提供服务或延迟提供服务的、叛乱、起义、骚乱、缺乏生产力或生产材料、火灾、洪水、风暴、爆炸、战争、银行或其他合作伙伴的失败、数字资产市场的崩溃、政府、司法或行政当局的行动，其他我们无法控制或无法控制的行为，或因第三方原因造成的其他无法提供服务或延迟提供服务的行为，我们不承担任何责任，或因此类失败或延误而导致您可能遭受的损失。",
  "tos101": "• 8.4 我们不能保证本网站所包含的所有信息、程序、文本等都是完全安全的，不受任何病毒、木马等恶意程序的干扰和破坏，因此，您在登录本网站时网站或使用本网站提供的任何服务，从本网站下载任何程序、信息和数据以及您的使用是您个人的决定，因此您应承担可能产生的任何和所有风险和损失。",
  "tos102": "• 8.5 我们对与本网站链接的任何第三方网站的任何信息、产品和业务，以及任何其他形式的不属于我们的内容不作任何保证和承诺；您使用第三方网站提供的任何服务、信息和产品是您个人的决定，因此您应承担由此产生的任何和所有责任。",
  "tos103": "• 8.6 我们对您使用本网站提供的服务不作任何明示或暗示的保证，包括但不限于适用性、无错误或遗漏、一致性、准确性、可靠性以及对特定目的的适用性，本网站提供的服务。此外，我们对本网站所提供服务所涵盖的技术和信息的有效性、准确性、正确性、可靠性、质量、稳定性、完整性和及时性不作任何承诺或保证。是否登录本网站或使用本网站提供的服务是您个人的决定，由此产生的一切风险和可能的损失由您自行承担。我们不对市场做出任何明示或暗示的保证，股票，贵金属，期货，理财，质押等产品的价值和价格；您理解并承认股票，贵金属，期货，理财，质押等产品市场不稳定，资产的价格和价值随时可能波动或崩盘，股票，贵金属，期货，理财，质押等产品的交易是基于您个人的自由意志和决定，因此您应承担任何和由此可能产生的所有风险和损失。",
  "tos104": "• 8.7 本协议中规定的保证和承诺是我们对我们在本协议下和通过本网站提供的服务作出的唯一保证和声明，并取代在任何其他协议中产生的任何和所有保证和承诺。方式和方式，无论是书面的还是文字的，明示的或暗示的。所有这些保证和声明仅代表我们自己的承诺和承诺，并不保证任何第三方遵守本协议中包含的保证和承诺。",
  "tos105": "• 8.8 我们不放弃本协议中未提及的任何权利，并在适用法律允许的最大范围内限制、免除或抵消我们的损害赔偿责任。",
  "tos106": "• 8.9 当您在本网站注册账户时，即视为您同意我们按照本协议规定的规则进行的任何和所有操作，由此产生的任何和所有风险由我方承担你。",
  "tos107": "9.协议终止",
  "tos108": "• 9.1 本网站有权根据本协议终止本网站向您提供的所有服务，本协议自本网站向您提供的所有服务终止之日起终止。",
  "tos109": "• 9.2 本协议终止后，您无权要求本网站继续向您提供任何服务或履行任何其他义务，包括但不限于要求本网站保留或向您披露任何您以前的原始帐户中的信息，或将其中未被阅读或发送的任何信息转发给您或任何第三方。",
  "tos110": "• 9.3 本协议的终止不妨碍守约方要求违约方承担其他责任。",
  "tos111": "10.知识产权",
  "tos112": "• 10.1 本网站包含的所有智力成果，包括但不限于网站标志、数据库、网站设计、文本和图形、软件、照片、视频、音乐、声音和上述文件的任何组合，以及知识产权软件编译、相关源代码和软件（包括小应用程序和脚本）的权利归本网站所有。您不得出于商业目的复制、修改、复制、传输或使用任何上述材料或内容。",
  "tos113": "• 10.2 本网站名称中包含的所有权利（包括但不限于商业信誉和商标、标识）均归本公司所有。",
  "tos114": "• 10.3 接受本协议后，即视为您基于自己的自愿，将您在本网站上发布的任何形式信息的所有版权全部无偿转让和转让给本网站，包括，但不限于著作权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、拍摄权、改编权、翻译权、编辑权和其他著作权人享有的可转让权利对任何侵犯该等著作权的行为，本网站有权提起诉讼，并就该等侵权行为获得全额赔偿。本协议适用于您在本网站发布的任何受版权法保护的内容，",
  "tos115": "• 10.4 您在使用本网站提供的服务期间，不得非法使用或处分本网站或任何其他人的知识产权。对于您在本网站发布的任何信息，您不得以任何方式发布或授权其他网站（或媒体）使用该信息。",
  "tos116": "• 10.5 您登录本网站或使用本网站提供的任何服务均不得视为我们向您转让任何知识产权。",
  "tos117": "11.信息保护",
  "tos118": "关于您个人信息的收集、使用和存储保护，以本网站公布的隐私政策为准。",
  "tos119": "12.计算",
  "tos120": "所有交易计算均经我们核实，所有计算方法均已在本网站公布，但我们不能保证您对本网站的使用不会受到干扰或没有错误。",
  "tos121": "13.出口管制",
  "tos122": "您理解并承认，根据相关法律规定，您不得出口、再出口、进口或转让本网站上的任何资料（包括软件）；因此，您在此承诺，您不会自愿承担或协助或参与任何上述出口或相关转移或其他违反适用法律法规的行为；如果您发现上述任何事件，您将向我们报告并协助我们处理。",
  "tos123": "14.转让",
  "tos124": "本协议约定的权利和义务对受益于该权利义务的各方的受让人、继承人、遗嘱执行人和管理人具有同等约束力。未经我们同意，您不得将您在本协议项下的任何权利或义务转让给任何第三方，但前提是我们可以在三十 (30) 天内随时将我们在本协议项下的权利和义务转让给任何第三方通知你。",
  "tos125": "15.可分割性",
  "tos126": "如果本协议的任何条款被任何有管辖权的法院认定为不可执行、无效或非法，本协议其余条款的有效性不受影响。",
  "tos127": "16.无代理",
  "tos128": "除非本协议另有规定，否则本协议中的任何内容均不得被视为将我们创建、暗示或以其他方式视为您的代理人、受托人或其他代表。",
  "tos129": "17.弃权",
  "tos130": "我们或您放弃追究另一方违约责任的权利或本协议约定的任何其他责任，不得解释或视为放弃追究另一方其他违约责任的权利; 未能行使任何权利或补救措施不得以任何方式解释为放弃此类权利或补救措施。",
  "tos131": "18.标题",
  "tos132": "此处所有标题仅为措辞方便，无意扩大或限制本协议条款和条件的内容或范围。",
  "tos133": "19.适用法律",
  "tos134": "19.1 您承认并同意，在提出任何争议或索赔之前，您将通过客服人员与我们联系以非正式方式解决争议，我们将尽快在内部解决相关争议；双方同意本着善意协商和解决任何争议（此类协商应保密并受适用规则保护，不得在任何诉讼中用作证据）。",
  "tos135": "19.2 您承认并同意，如发生任何根据第 19.1 条无法解决的争议、争议、分歧或索赔，包括与本条款的存在、有效性、解释、履行、违反或终止有关的任何争议或与本条款有关或因本条款引起的任何争议（统称为“争议”），双方应将争议提交国际商会国际仲裁院（“ICC 国际仲裁院”）根据ICC 仲裁规则生效。仲裁应在伯利兹进行，适用于根据国际商会仲裁规则确定的适用法律。除非本协议双方另有约定，根据国际商会仲裁规则，只能指定一名仲裁员。仲裁员对自己的管辖权享有排他性的裁决权，包括但不限于对仲裁协议的存在、范围或有效性或任何索赔或反请求的可仲裁性提出任何异议。仲裁应以英语进行。无论采用何种仲裁方式，仲裁员均应作出合理的书面决定，对仲裁裁决、其基本结论和结论（如有）作出解释。仲裁裁决为终局裁决，对双方具有约束力，可由任何有管辖权的法院强制执行。仲裁应以英语进行。无论采用何种仲裁方式，仲裁员均应作出合理的书面决定，以解释仲裁裁决、其基本结论和结论（如有）。仲裁裁决为终局裁决，对双方具有约束力，可由任何有管辖权的法院强制执行。仲裁应以英语进行。无论采用何种仲裁方式，仲裁员均应作出合理的书面决定，对仲裁裁决、其基本结论和结论（如有）作出解释。仲裁裁决为终局裁决，对双方具有约束力，可由任何有管辖权的法院强制执行。",
  "tos136": "20.协议的生效和解释",
  "tos137": "20.1 本协议自您点击本网站注册页面，完成注册程序，获取本网站账号和密码时生效，对您和本网站具有约束力。",
  "tos138": "20.2 本协议的最终解释权归本网站所有。",
  "tos139": "了解您的客户和反洗钱政策",
  "tos140": "前言",
  "tos141": "• 1.1 我们确保遵守“了解您的客户”和反洗钱法律法规，不会故意违反“了解您的客户”和反洗钱政策。在我们合理控制的范围内，我们将采取必要的措施和技术为您提供安全可靠的服务，以最大程度地保护您免受洗钱造成的损失。",
  "tos142": "• 1.2 我们的了解您的客户和反洗钱政策是一个综合性的国际政策体系，包括您所在司法管辖区的了解您的客户和反洗钱政策。我们强大的合规框架确保我们满足本地和全球层面的监管要求和监管标准，并确保我们网站的运营可持续性。",
  "tos143": "我们了解您的客户和反洗钱政策的内容",
  "tos144": "• 2.1 我们颁布和更新“了解您的客户”和反洗钱政策，以符合相关法律法规规定的标准；",
  "tos145": "• 2.2 我们发布和更新与本网站运营相关的一些指导方针和规则，我们的工作人员将按照指导方针和规则为您提供全程服务；",
  "tos146": "• 2.3 我们设计并完善内部监控和交易控制程序，如严格的身份认证程序，并组建专业的反洗钱团队；",
  "tos147": "• 2.4 我们以风险防范为基础，对客户进行尽职调查和持续监督；",
  "tos148": "• 2.5 审查并定期检查现有交易；",
  "tos149": "• 2.6 向主管当局报告可疑交易；",
  "tos150": "• 2.7 身份证明文件、地址证明和交易记录的证明文件将至少保存六（6）年；如果它们已提交给监管机构，请理解将不会向您提供单独的通知；",
  "tos151": "• 2.8 整个交易过程中禁止使用信用卡；",
  "tos152": "身份信息及其核实确认",
  "tos153": "• 3.1 身份信息",
  "tos154": "• 3.1.1 根据相关司法管辖区的法律法规和有关实体的性质，我们收集的您的信息内容可能有所不同，原则上，我们将收集您的以下信息，如果：您以个人身份注册： 个人基本信息：您的姓名、地址（和永久地址，如果两者不同）、出生日期和国籍以及其他可用信息。身份认证应当以官方或其他类似机关出具的证件为依据，如护照、身份证或相关司法管辖区要求出具的其他身份证件。您提供的地址将以适当的方式进行验证，例如检查您使用的交通工具的票价、您的利率账单或选民登记册。有效照片：注册前，您必须提供一张您将身份证件放在胸前的照片；联系方式：电话/手机号码和有效的电子邮件地址。",
  "tos155": "• 3.1.2 如果您是公司或任何其他类型的法律实体，我们将收集您的以下信息以确定您的账户或信托账户的最终受益人。您的公司注册和公司注册证明；公司章程和备忘录的副本；公司股权结构和股权描述的详细证明材料，以及董事会关于指定公司授权代理人负责公司网站账户开立和执行的决定；公司董事、主要股东的身份证明文件，以及按照相关规定要求提供的公司网站账户的授权签字人；公司的主要营业地址，如果与公司的主要营业地址不同，则公司的邮寄地址。如果公司的本地地址与其主要业务地址不同，则该公司将被视为高风险客户，因此公司将被要求提供额外的文件。其他证明文件、主管部门出具的文件以及我们根据相关司法管辖区的法律法规和贵实体的具体性质可能认为必要的其他文件。该公司应被视为高风险客户，因此该公司将被要求提供额外的文件。其他证明文件、主管部门出具的文件以及我们根据相关司法管辖区的法律法规和贵实体的具体性质可能认为必要的其他文件。该公司应被视为高风险客户，因此该公司将被要求提供额外的文件。其他证明文件、主管部门出具的文件以及我们根据相关司法管辖区的法律法规和贵实体的具体性质可能认为必要的其他文件。",
  "tos156": "• 3.1.3 我们只接受您的身份信息的英文和中文版本；如果您的身份信息不是两种语言中的任何一种，您应将您的身份信息翻译成英文并经过正式公证。",
  "tos157": "• 3.2 确认和验证",
  "tos158": "• 3.2.1 您需要提供身份证明文件的正反两面。",
  "tos159": "• 3.2.2 您需要向我们提供一张照片，显示您将身份证件放在胸前。",
  "tos160": "• 3.2.3 认证文件的复印件应与原件核对。尽管如此，如果受信任且合适的证明人能够证明此类副本是其原件的准确和全面的副本，则此类副本应被视为可以接受。此类证明人包括大使、司法人员、地方法官等。",
  "tos161": "• 3.2.4 账户最终受益人和控制人的身份应基于确定哪些个人最终拥有或控制直接客户和/或确定正在进行的交易是由另一个人执行的。如果您是企业，则应验证其主要股东（例如，持有该企业 10% 或以上表决权的股东）的身份。一般情况下，持有公司25%股份的股东将被视为涉及平均风险，并核实股东身份；持有10%以上表决权或股份的股东，视为存在高风险，应当核实股东身份。",
  "tos162": "交易监管",
  "tos163": "• 4.1 我们根据安全要求和实际交易情况不断设置和调整每日交易和提现限额；",
  "tos164": "• 4.2 如果交易在您注册的账户中频繁发生或超出合理情况，我们的专业团队将评估和确定该交易是否可疑；",
  "tos165": "• 4.3 如果我们根据评估确定特定交易可疑，我们可能会采取暂停交易或拒绝交易等限制性措施，如果可能，我们甚至可能尽快撤销交易，以及向主管当局报告，但不通知您；",
  "tos166": "• 4.4 我们保留拒绝不符合反洗钱国际标准或可能被视为政治和公众人物的申请人的注册申请的权利；我们保留暂停或终止根据我们自己的评估确定为可疑的交易的权利，但这并不违反我们对您的任何义务和义务。",
  "tos167": "（MQG Capital）",
  "tos168": "2021-06-21 14:58:41",

  "coAgreement1": "（MQG Capital）服务协议",
  "coAgreement2": "（MQG Capital）向用户确认：",
  "coAgreement3": "1、当用户在（MQG Capital）网站注册页面点击同意注册按钮，完成注册程序，并获得（MQG Capital）帐号和密码时，即视为用户与（MQG Capital）达成共识《用户协议》（以下简称“本协议”），用户使用本协议进入（MQG Capital）以及就（MQG Capital）提供的交易服务所达成的一切协议。",
  "coAgreement4": "2、（MQG Capital）及用户已仔细阅读本用户协议中的所有条款以及（MQG Capital）发布的免责声明和隐私条款的内容。他们已知晓、理解并接受本协议及前述免责声明和隐私条款。它作为确定双方权利和义务的依据。（MQG Capital）的“免责声明”和“隐私条款”为本协议必不可少的当事人。用户接受本协议即视为接受（MQG Capital））《免责声明》及《隐私条款》的全部内容。本协议内容包括（MQG Capital）已发布的正文及各项规则、声明、说明等。信息或可能在未来发布。所有规则，声明，",
  "coAgreement5": "3、本协议不涉及（MQG Capital）用户与其他用户之间因数字资产交易产生的法律关系和法律纠纷，如果本交易所注册用户之间涉嫌欺诈，或者是恶意套取其它用户资金的，本交易所有权质押涉事双方交易所账户所有资金，直到调查情况结束冰给与明确的处理结果，才能解除账户的冻结。",
  "coAgreement6": "4.通知：受部分国家政策影响，部分国家已全面停止股票，贵金属，期货，理财，质押等产品投资及融资活动。如相关政策、法律、法规发生变化，用户需自行承担交易资产的风险。请您慎重考虑后考虑是否注册使用股票，贵金属，期货，理财，质押等产品交易服务。因政策或法律原因，我们不接受中国大陆一级市场、美国一级市场、伊朗、伊拉克、叙利亚、苏丹、朝鲜、古巴，中国，巴基斯坦人士注册投资本平台。美国，加拿大，中东部分国家地区印度部分国家地区的用户，只能以二级市场的形式进入本交易所进行投资，您在本平台的资产难以得到保护，您应对交易资产的安全负责。",
  "coAgreement7": "一、 定义",
  "coAgreement8": "1、（MQG Capital）：指由（MQG Capital）运营管理的股票，贵金属，期货，理财，质押等产品交易平台，为股票，贵金属，期货，理财，质押等产品玩家提供在线交易服务，如：黄金，原油，股票通过这个在线交易平台。本协议以下“贵金属，期货，股票”指网络交易平台贵金属，期货，股票官网和贵金属，期货，股票交易机构App。",
  "coAgreement9": "2、“用户”：指接受并同意本协议所有条款及（MQG Capital）发布、更新的其他法律条款和操作规则的（MQG Capital）注册会员。",
  "coAgreement10": "3、“用户注册”是指用户登录（MQG Capital），按要求填写相关信息，确认同意履行相关用户协议的过程。",
  "coAgreement11": "4、“股票，贵金属，期货，理财，质押等产品的交易”：指用户通过（MQG Capital）平台进行的股票，贵金属，期货，理财，质押等产品的交易活动。",
  "coAgreement12": "5、“充值”：指用户为购买或出售各类资产或者法定货币而向（MQG Capital）平台预存股票，贵金属，期货，理财，质押等产品的资产。",
  "coAgreement13": "6、“交易手续费”：指用户在（MQG Capital）发生交易而支付给（MQG Capital）的交易手续费。",
  "coAgreement14": "7、“税收”：指用户在（MQG Capital）产生收益以后，根据交易所注册地的相关法律法规，交易所协助政府征收的个人收益税收。印度政府对于民众征收总收益18%的个人所得税收，印度政府为了吸收（MQG）入住，给与了3%-5%的合作让利作为扶持条件，我们把这一利好完全让利给广大（MQG）（印度）的注册用户，也就是说，通过（MQG）进行投资，获得收益以后，从（MQG）进行税收代缴，只需要根据实际收益的比例，缴纳13%-15%的个人所得税。",
  "coAgreement15": "8、“质押担保费用”：指用户账户一旦出现系统设置默认评估的风险状态，而导致出现的后续结果，为了释放用户资金，交易所又需要调查核实情况的前提下，用户缴纳在交易所进行账户担保的费用，一般情况下，会在一定周期以后退还。",
  "coAgreement16": "二。用户注册",
  "coAgreement17": "一、报名资格",
  "coAgreement18": "用户承诺：用户具有完全民事权利能力和行为能力，或用户不具有完全民事权利能力和行为能力，点击“同意注册”按钮，即视为用户已征得法定代理人同意，经法定代理人注册并使用（MQG Capital）",
  "coAgreement19": "2. 注册目的",
  "coAgreement20": "用户承诺：用户注册要求不以违反任何法律法规或交易数字资产为目的。",
  "coAgreement21": "三、报名流程",
  "coAgreement22": "(1) 用户（“您”）同意按照（MQG Capital）用户注册页面要求的信息，提供完整、准确、真实的信息，包括但不限于电话号码、身份证件、有效账号等密码。",
  "coAgreement23": "(2) 用户合法、完整地提交注册所需的全部信息后，将获得一个（MQG Capital）交易账户，该账户可用于登录（MQG Capital）交易平台。",
  "coAgreement24": "(3) 当用户获得（MQG Capital）帐号和密码时，即视为用户注册成功，用户同意接收（MQG Capital）发送的与（MQG Capital）网站管理运营相关的邮件或短信。信息。",
  "coAgreement25": "(4) 在（MQG Capital）成功注册账户后，您同意提供准确真实的文件，以便验证您的身份，这也称为“实名认证”。",
  "coAgreement26": "三、用户访问限制",
  "coAgreement27": "1.（MQG Capital）将遵守美国和一般国际制裁的所有制裁。您对（MQG Capital）服务和网站的使用受国际出口管制和经济制裁要求的约束。通过（MQG Capital）网站、APP 或其他服务发送、接收、购买、出售、交易或存储贵股票，贵金属，期货，理财，质押等产品，即表示您同意您将遵守这些要求。如果出现以下情况，您不得通过本网站获取和交易贵金属，期货，部分加密货币或使用任何 （MQG Capital）服务：",
  "coAgreement28": "1) 您在古巴、伊朗、朝鲜、苏丹、叙利亚、中国，巴基斯坦，阿富汗、克里米亚和塞瓦斯托波尔或任何其他受印度禁运、联合国制裁、欧盟或英国财政部的金融制裁制度（每个“受制裁国家”），或者如果您是适用当局（包括但不限于金融制裁实施办公室）不时发布的经济制裁名单上的人（部分英国财政部），印度商务部的拒绝人员名单、未核实名单或实体名单，或欧盟金融制裁制度）（“被制裁人”）；或者2) 您打算向受制裁国家（或受制裁国家的国民或居民）或受制裁人员提供任何获取或存储的贵金属，期货，股票或（MQG Capital）服务。",
  "coAgreement29": "2.（MQG Capital）不为印度部分地区一级市场和中国，巴基斯坦，加拿大以及中东部分国家现有居民的个人账户以及位于、成立于中国，巴基斯坦，美国，加拿大以及中东部分国家和地区的实体或居民的公司账户开通或提供服务.",
  "coAgreement30": "3、在使用（MQG Capital）提供的任何服务前，您承诺您不属于上述任何类别。",
  "coAgreement31": "四、服务内容",
  "coAgreement32": "（MQG Capital）提供在线交易平台服务，供用户通过（MQG Capital）进行股票，贵金属，期货，理财，质押等产品交易活动。",
  "coAgreement33": "一、服务协议",
  "coAgreement34": "（一）您有权在本网站查看股票，贵金属，期货，理财，质押等产品各类产品的实时行情和交易信息，有权通过本网站提交股票，贵金属，期货，理财，质押等产品资产交易订单，完成股票，贵金属，期货，理财，质押等产品的资产交易。",
  "coAgreement35": "(2) 您有权在本网站会员账户下查看本网站信息，有权使用本网站提供的功能，美国，中国，巴基斯坦和加拿大部分地区，中东部分国家和地区，无法完整的使用所有功能。",
  "coAgreement36": "(3) 您有权按照本网站公布的规则参加本网站组织的活动。",
  "coAgreement37": "(4) 本站承诺向您提供的其他服务。",
  "coAgreement38": "二、服务规则",
  "coAgreement39": "服务规则您承诺遵守以下网站服务规则：",
  "coAgreement40": "(1) 您应遵守适用法律、法规、规章和政策要求的规定，确保您账户中所有股票，贵金属，期货，理财，质押等产品来源的合法性，不得从事任何违法或其他损害权益的活动和本网站或任何第三方的利益，如发送或接收非法、非法或侵犯他人权益的信息，发送或接收传销信息或造成其他危害的信息或言论，未经授权使用或伪造本网站的电子邮件标头信息等。",
  "coAgreement41": "(2) 您应当遵守适用的法律法规，妥善使用和保管您在本网站的账户和登录密码、您的金融交易密码以及您在注册账户时提供的与您的账户绑定的手机号码，作为以及通过手机收到的验证码的安全性。您应对您使用本网站账户及登录密码、金融交易密码、发送至您手机的验证码进行的任何及所有操作以及由此产生的一切后果承担全部责任。当您发现您在本网站的账户、您的登录密码、金融交易密码或手机验证码被任何未经授权的第三方使用时，如发现其他与您的账户安全有关的问题，您应及时有效地告知（MQG Capital），并要求（MQG Capital）暂停为您在（MQG Capital）开设的账户提供服务。",
  "coAgreement42": "(3) 您应当遵守本协议以及本网站随时发布、更新的其他服务条款和操作规则。",
  "coAgreement43": "五、交易规则",
  "coAgreement44": "1.浏览交易信息",
  "coAgreement45": "在浏览本网站的交易信息时，您应当仔细阅读交易信息中包含的全部内容，包括但不限于价格、委托金额、交易手续费、买入或卖出方向。您只有在完全接受交易信息中包含的全部内容后，才能点击按钮进行交易。",
  "coAgreement46": "2.提交佣金",
  "coAgreement47": "审核交易信息并确认无误后，即可提交交易委托。您提交交易委托后，即您授权本网站为您代理相应的交易撮合，当有交易达到您委托的价格时，本网站将自动完成交易撮合，恕不另行通知。",
  "coAgreement48": "3.查看交易明细",
  "coAgreement49": "您可以通过您的账户查看相关交易记录。",
  "coAgreement50": "4. 撤销/修改订单",
  "coAgreement51": "您有权在交易达成前随时取消或修改佣金，一旦交易正式发起，或者是用户决定性的请求发起，被系统予以确认以后，将无法再进行更改。",
  "coAgreement52": "六、用户的权利",
  "coAgreement53": "1、用户有权选择是否成为（MQG Capital）会员。如果用户选择成为（MQG Capital）的注册用户，则可以创建和修改自己的昵称。用户名、昵称的命名和使用应遵守相关法律法规和网络道德规范，不得侵犯或涉嫌侵犯他人合法权益。由此产生的法律责任和后果由用户自行承担（MQG Capital）不承担任何审查义务，不承担任何责任。",
  "coAgreement54": "2、用户有权按照本协议约定接受（MQG Capital）提供的股票，贵金属，期货，理财，质押等产品资产交易平台服务。",
  "coAgreement55": "3、用户有权修改个人账户中所有可修改的信息，选择昵称和输入介绍文字，并自行决定是否提供不需要的内容。",
  "coAgreement56": "4、用户有权参与（MQG Capital）提供的各类线上线下活动。",
  "coAgreement57": "5、用户有权按照（MQG Capital）网站的规定享受（MQG Capital）网站提供的其他各项服务。",
  "coAgreement58": "6、用户有权随时终止使用（MQG Capital）服务。",
  "coAgreement59": "7、用户有权随时提取（MQG Capital）中的股票，贵金属，期货，理财，质押等产品资产余额，但需向（MQG Capital）支付相应的提取手续费，如果达到税收结算标准，需要向注册地政府进行税收的缴纳，金额过大时，需要提升账户VIP的等级提升，方可完成资金划转。",
  "coAgreement60": "8、用户有权参与（MQG Capital）社区，发表符合国家法律法规和（MQG Capital）网络社区规则的文章和观点。",
  "coAgreement61": "七. 用户的义务",
  "coAgreement62": "1、用户对注册时提供的个人资料的真实性、有效性和安全性负责。",
  "coAgreement63": "2、用户不得以任何方式恶意注册（MQG Capital）账号，包括但不限于以营利、投机、套现、中奖等为目的注册多个账号。用户不得盗用其他用户账号，用户的账户资金也不得用户非法目的。",
  "coAgreement64": "3、用户在（MQG Capital）进行交易时，不得恶意干扰交易的正常进行，扰乱交易秩序。",
  "coAgreement65": "4、用户不得以任何技术手段或其他方式干扰（MQG Capital） 的正常运营或干扰其他用户使用 （MQG Capital）服务。",
  "coAgreement66": "5、如用户因网上交易与其他用户发生诉讼，（MQG Capital） 无须通过司法或行政途径提供相关信息，但我们接受本交易所用户对于其它注册用户的投诉，跟进实际情况来做出处理结果。",
  "coAgreement67": "6、用户不得以任何形式利用（MQG Capital）作为从事各类违法活动的场所、平台、媒介。未经（MQG Capital）授权或许可，用户不得利用本站名称从事任何商业活动，也不得以任何形式利用（MQG Capital） 作为从事商业活动的场所、平台和媒介。",
  "coAgreement68": "7、用户在（MQG Capital）以各种形式发布的所有信息，均应符合国家有关法律法规和本网站相关规定，符合社会公序良俗，不侵犯用户合法权益。任何第三方主体，否则，用户将承担由此产生的一切法律后果，并因此遭受的（MQG Capital）损失，有权向用户追偿。",
  "coAgreement69": "8、如用户违反上述规则，（MQG Capital）有权直接采取一切必要措施，包括但不限于删除用户发布的内容，取消用户获得的星级、荣誉、虚拟财富等暂停或封禁该用户账号，并取消其因违规获得的利益，甚至通过诉讼追究用户的法律责任。",
  "coAgreement70": "八. 知识产权",
  "coAgreement71": "1. 服务中包含的所有智力成果，包括但不限于平台标识、数据库、网站设计、文字图形、软件、照片、视频、音乐、声音及其任意组合、知识产权软件编译中的相关源代码和软件（包括小程序和脚本）均归本平台所有。您不得为商业目的复制、修改、复制、传输或使用任何前述材料或内容。",
  "coAgreement72": "2、本协议项下服务名称中包含的所有权利（包括但不限于商誉、商标、标识）均归平台所有。",
  "coAgreement73": "3、任何经授权浏览、复制、打印、传播属于（MQG Capital）平台的信息内容，不得用于商业目的，所有对信息内容及其任何部分的使用均应包含本版权声明。",
  "coAgreement74": "4、您一经接受本协议，即视为您基于自愿自愿向（MQG Capital） 独家、无偿转让、受让。您在（MQG Capital）上发布的任何形式的信息的所有版权，包括但不限于著作权、发行权、租赁权、展览权、表演权、放映权、播放权、信息网络传播权、拍摄权、改编权、著作权人享有的翻译权、编译权及其他可转让权利， （MQG Capital） 有权对任何侵犯该著作权的行为提起诉讼并获得全部侵权赔偿。 ",
  "coAgreement75": "5、您在使用服务过程中不得非法使用或处分本平台或他人的知识产权。您不得以任何方式发布或授权任何其他网站（及媒体）以任何方式使用本平台发布的信息。如果用户有上述行为，（MQG Capital） 保留向用户追偿的权利。给（MQG Capital）网站造成损失。",
  "coAgreement76": "6、恶意散播，造谣，诋毁交易所的负面消息和言论，交易所有权永久冻结用户账户，一旦用户正式注册，就视为接受本协议内容。",
  "coAgreement77": "九. 特别声明",
  "coAgreement78": "因信息网络设备维护、信息网络连接故障、计算机、通讯或其他系统故障、停电、天气状况、意外事故、劳工行动、劳资纠纷等原因导致平台无法提供或延迟提供服务、起义、起义、骚乱、缺乏生产力或生产材料、火灾、洪水、风暴、爆炸、战争、银行或其他合作伙伴的失败、交易市场的崩溃、政府、司法或行政当局的行动、其他不在我们控制范围内或我们无法控制的，或因第三方原因造成的行为。（MQG Capital））对该等未能提供服务或延迟提供服务不承担任何责任，",
  "coAgreement79": "为了在市场上保持良好的声誉并遵守某些司法管辖区的独特监管要求，我们决定不为来自中国大陆、美国部分地区一级市场、巴基斯坦，加拿大一级市场，伊朗、伊拉克、叙利亚、苏丹、朝鲜或古巴的客户提供服务.",
  "coAgreement80": "十．协议的变更和终止",
  "coAgreement81": "1、我们保留不时对本协议进行修改的权利，并以公告方式在本网站披露，而无需另行通知您您的权利。修改日期将在修改后的协议首页上注明。修改后的协议将在网站上公布后立即生效。您应不时浏览本网站，关注本协议修改时间、内容等信息。如果您不同意修改后的内容，您应立即停止使用本网站提供的服务；如果您继续使用本网站提供的服务，即视为您接受并同意接受修改后的协议的约束。",
  "coAgreement82": "2.协议终止",
  "coAgreement83": "1) 本网站有权依据本协议约定注销用户的账户，本协议自账户注销之日终止。",
  "coAgreement84": "2) 本网站有权依据本协议约定终止本网站向您提供的所有服务，本协议自本网站向您提供的所有服务终止之日终止。",
  "coAgreement85": "3) 本协议终止后，您无权要求本网站继续为您提供任何服务或履行任何其他义务，包括但不限于要求本网站为您保留或向您披露任何信息在您以前的原始帐户中，或将其中未被阅读或发送的任何信息转发给您或任何第三方。",
  "coAgreement86": "4) 本协议的终止不妨碍守约方要求违约方承担其他责任。",
  "coAgreement87": "十一. 隐私政策",
  "coAgreement88": "一、适用范围",
  "coAgreement89": "1) 用户注册（MQG Capital）账号时，用户根据（MQG Capital）要求提供的个人注册信息，包括但不限于身份证信息；",
  "coAgreement90": "2) 当用户使用（MQG Capital）服务或访问（MQG Capital）网站时，（MQG Capital）自动接收并记录用户浏览器上的服务器值，包括但不限于IP地址、网页记录等数据。用户;",
  "coAgreement91": "3) （MQG Capital）收集的用户在（MQG Capital）交易的相关数据，包括但不限于竞价、购买记录；",
  "coAgreement92": "4) （MQG Capital）应通过合法途径获取个人信息。",
  "coAgreement93": "2.信息的使用",
  "coAgreement94": "未经用户事先许可，（MQG Capital）不会将用户的个人信息出售或出借给任何人。（MQG Capital）也不允许任何第三方以任何手段收集、编辑、出售或者无偿传播用户的个人信息。",
  "coAgreement95": "三、信息保护",
  "coAgreement96": "（MQG Capital）应对用户身份信息和交易信息保密，不得向任何单位和个人提供用户身份信息和交易信息，法律法规另有规定的除外。",
  "coAgreement97": "十二。反洗钱",
  "coAgreement98": "1.（MQG Capital）遵守并执行《反洗钱和恐怖集资（金融机构）条例》和国际反洗钱法的相关规定，进行用户身份识别、用户身份识别资料、交易记录保存系统，以及大型和可疑交易系统的报告。",
  "coAgreement99": "2、用户在注册、修改交易密码或提现密码时，需提供并上传身份证复印件，（MQG Capital）将对用户提供的身份证信息进行识别比对。（MQG Capital）有合理理由怀疑当用户以虚假身份注册时，我们有权拒绝注册或删除已经注册的账户。",
  "coAgreement100": "3、（MQG Capital）参照《反洗钱和恐怖活动集资（金融机构）条例》等相关规定，维护大额交易记录和涉嫌洗钱交易记录。当监管机构要求记录大额交易和可疑交易时，本网站应向监管机构报告。",
  "coAgreement101": "4.（MQG Capital）保存用户身份信息和大额交易及可疑交易记录，依法协助并配合司法机关和行政执法部门打击洗钱活动，协助司法机关、海关、税务等部门依法依规查询、冻结、扣划用户存款（包括但不限于现金、代币等）。",
  "coAgreement102": "5、为保证用户资产安全，入金姓名必须与实名认证姓名一致。",
  "coAgreement103": "6、交易所一律实行实名制，确保用户资金安全和用户资金合法。在本交易所进行贷款，税收缴纳，提升VIP等级的费用缴纳等情况时，我们只接受用户本人完成，不允许与注册用户名字不一致的资金来进行缴纳。",
  "coAgreement104": "十三。风险提示",
  "coAgreement105": "1、您在开始交易前应了解交易的性质和您所面临的风险程度。您必须根据自己的投资经验、目的、财力和风险承受能力，慎重考虑此类交易是否适合您。",
  "coAgreement106": "2、股票，贵金属，期货，理财，质押等产品资产交易具有极高的风险。用户参与股票，贵金属，期货，理财，质押等产品的资产交易，应自行控制风险，评估股票，贵金属，期货，理财，质押等产品资产投资的价值和投资风险，并承担损失全部投资的经济风险。",
  "coAgreement107": "3、本网站不保证相关市场分析、行情分析等的正确性和适用性，对于您从介绍人或其他任何组织、员工处拥有或获得的这些信息或建议，本网站无法控制、也不支持或保证其交易的准确性或完整性，由此产生的一切风险由您自行承担，与我们无关。",
  "coAgreement108": "4、本交易所提供的短线交易，质押生息，买卖，杠杆，持有等交易方式，结算以交易所数据为准，用户参与时，自行评估投资风险系数，交易所不承担任何资金损失责任。",
  "coAgreement109": "本网站有权根据上述主管部门的要求或其他第三方的要求，或根据上述规定，协助提供相应的用户数据或采取相应的查封、冻结、移交等措施。本网站的合理判断。由此造成的用户隐私泄露、账号无法操作以及由此给本站用户造成的损失，本站不承担任何责任。",
  "coAgreement110": "5、因相关法律、法规、规范性文件的制定或修改，暂停或禁止股票，贵金属，期货，理财，质押等产品的交易，由此造成的经济损失由用户承担。",
  "coAgreement111": "十四。违约责任",
  "coAgreement112": "1、股票，贵金属，期货，理财，质押等产品或用户违反本协议的行为构成违约，违约方应向签约方承担违约责任。",
  "coAgreement113": "2、因用户提供的信息不实、不完整、不准确导致（MQG Capital）造成损失的， （MQG Capital）有权要求用户赔偿（MQG Capital）的损失。",
  "coAgreement114": "3、用户违反本协议的法律法规，在（MQG Capital）或使用 （MQG Capital）服务中从事违法活动的， （MQG Capital）有权立即终止向其提供 （MQG Capital）服务，删除其账号，并要求赔偿给（MQG Capital）造成的损失。",
  "coAgreement115": "4、若用户通过技术手段干扰（MQG Capital）的运营或干扰其他用户使用（MQG Capital），（MQG Capital）有权立即删除其（MQG Capital）账号，并有权要求赔偿为其造成的损失。",
  "coAgreement116": "5、用户如以虚构事实等方式恶意诋毁（MQG Capital）名誉的，（MQG Capital）有权要求用户向（MQG Capital）公开道歉，并赔偿给（MQG Capital）造成的损失，并有权有权终止向其提供（MQG Capital）服务。",
  "coAgreement117": "十五。分离性",
  "coAgreement118": "如果本协议的任何条款因任何原因被视为非法、无效或不可执行，则这些条款应被视为可分割的，而不影响任何其他条款的法律效力。",
  "coAgreement119": "十六。争议解决",
  "coAgreement120": "用户与（MQG Capital）之间因本协议引起的或与本协议相关的任何争议，双方应友好协商解决。协商不成时，双方有权向（MQG Capital）网站所在地的法院提起诉讼。",
  "coAgreement121": "十七。协议的效力和解释",
  "coAgreement122": "本协议于用户点击（MQG Capital）注册页面进行注册并完成注册程序，获得 （MQG Capital）帐号和密码时生效，对 （MQG Capital）与用户具有约束力。",
  "coAgreement123": "隐私政策",
  "coAgreement124": "一、适用范围",
  "coAgreement125": "1) 用户注册（MQG Capital）账号时，用户根据（MQG Capital）要求提供的个人注册信息，包括但不限于身份证信息；",
  "coAgreement126": "2) 当用户使用（MQG Capital）服务或访问（MQG Capital）网站时，（MQG Capital）网络自动接收并记录用户浏览器上的服务器值，包括但不限于IP地址、网页记录等数据用户要求；",
  "coAgreement127": "3) （MQG Capital）收集的用户在（MQG Capital）上交易的相关数据，包括但不限于出价、购买记录；",
  "coAgreement128": "4)  （MQG Capital）通过合法途径获得的其他用户的个人信息。",
  "coAgreement129": "信息的使用",
  "coAgreement130": "（MQG Capital）不会将用户的个人信息出售或出借给任何人，除非事先征得用户的同意。（MQG Capital）不允许任何第三方以任何手段收集、编辑、出售或者无偿传播用户的个人信息。",
  "coAgreement131": "信息保护",
  "coAgreement132": "（MQG Capital）对用户身份信息和交易信息保密，除法律法规另有规定外，不向任何单位和个人提供用户身份信息和交易信息。",
  "coAgreement133": "免责声明",
  "coAgreement134": "股票，贵金属，期货，理财，质押等产品投机性强，不同的股票，贵金属，期货，理财，质押等产品也存在各种风险，如暴涨、暴跌、市场操纵、交易品种技术缺陷等。（MQG Capital）仅为股票，贵金属，期货，理财，质押等产品爱好者提供一个免费的在线交易平台。不对股票，贵金属，期货，理财，质押等产品的投资价值承担任何审查、保证和赔偿责任。（MQG Capital）上所有股票，贵金属，期货，理财，质押等产品交易造成的资产损失都是统一的。这是用户的责任。 （MQG Capital）与 （MQG Capital）上的投资者约定如下：",
  "coAgreement135": "1、您在开始交易前应该了解交易的性质和您所面临的风险等级。您必须根据自己的投资经验、目的、财力和风险承受能力，慎重考虑此类交易是否适合您。",
  "coAgreement136": "2、股票，贵金属，期货，理财，质押等产品的资产交易具有极高的风险。用户参与股票，贵金属，期货，理财，质押等产品资产交易，应自行控制风险，评估股票，贵金属，期货，理财，质押等产品资产投资的价值和投资风险，并承担损失全部投资的经济风险。",
  "coAgreement137": "3、本网站不保证相关市场分析、行情分析等的正确性和适用性，对于您从介绍人或其他任何组织和员工处已经或将要获得的这些信息或建议，本网站无法控制，也不支持或保证其与交易相关的准确性或完整性，由此产生的一切风险由您自行承担，与我们无关。",
  "coAgreement138": "本网站有权根据上述主管部门的要求或其他第三方的要求，或根据上述规定，协助提供相应的用户数据或采取相应的查封、冻结、移交等措施。本网站的合理判断。由此造成的用户隐私泄露、账号无法操作以及由此给本站用户造成的损失，本站不承担任何责任。",
  "coAgreement139": "5、因有关法律、法规、规范性文件的制定或修改，暂停或禁止股票，贵金属，期货，理财，质押等产品资产交易，由此造成的经济损失由用户承担。",
  "coAgreement140": "6、本站对本《（MQG Capital）服务协议》拥有最终解释权。",
  "coAgreement141": "如果您不能接受上述约定，请不要在（MQG Capital）上进行交易。",
  "coAgreement142": "（MQG Capital）法务部",

  "Disclaimers1": "免责声明",
  "Disclaimers2": "（MQG）（印度）以提供股票，贵金属，期货，理财，质押等产品为主，但是同时，我们需要声明的是：股票，贵金属，期货，理财，质押等产品市场投机性强，不同的股票，贵金属，期货，理财，质押等产品也存在各种风险，如暴涨、暴跌、市场操纵、交易品种技术缺陷等。（MQG Capital）仅为股票，贵金属，期货，理财，质押等产品爱好者提供一个免费的在线交易平台。不对股票，贵金属，期货，理财，质押等产品为主的投资价值承担任何审查、保证和赔偿责任。（MQG Capital）上所有股票，贵金属，期货，理财，质押等产品交易造成的资产损失都是统一的。这是用户的责任。（MQG Capital）与（MQG Capital）上的投资者约定如下：",
  "Disclaimers3": "1、您在开始交易前应该了解交易的性质和您所面临的风险等级。您必须根据自己的投资经验、目的、财力和风险承受能力，慎重考虑此类交易是否适合您。",
  "Disclaimers4": "2、股票，贵金属，期货，理财，质押等产品交易具有极高的风险。用户参与股票，贵金属，期货，理财，质押等产品的交易，应自行控制风险，评估股票，贵金属，期货，理财，质押等产品资产投资的价值和投资风险，并承担损失全部投资的经济风险。",
  "Disclaimers5": "3、本网站不保证相关市场分析、行情分析等的正确性和适用性，对于您从介绍人或其他任何组织和员工处已经或将要获得的这些信息或建议，本网站无法控制，也不支持或保证其与交易相关的准确性或完整性，由此产生的一切风险由您自行承担，与我们无关。",
  "Disclaimers6": "4、本网站有权根据上述主管部门的要求或其他第三方的要求，或根据上述规定，协助提供相应的用户数据或采取相应的查封、冻结、移交等措施。本网站的合理判断。由此造成的用户隐私泄露、账号无法操作以及由此给本站用户造成的损失，本站不承担任何责任。",
  "Disclaimers7": "5、因有关法律、法规、规范性文件的制定或修改，暂停或禁止贵金属，期货，部分加密货币交易，由此造成的经济损失由用户承担。",
  "Disclaimers8": "6、本站对本《（MQG Capital）服务协议》拥有最终解释权。",
  "Disclaimers9": "（MQG Capital）法务部",

  "privacyAgreement1": "隐私条款：",
  "privacyAgreement2": "一. 坚持法律",
  "privacyAgreement3": "坚守交易所注册地政府相关的国家法律、制度和规范，严格保护好每一位注册用户的个人隐私。",
  "privacyAgreement4": "二、使用目的",
  "privacyAgreement5": "本公司将客户的个人信息用于以下目的：",
  "privacyAgreement6": "1、提供和改进公司的产品或服务，争取带给用户更好的使用体验。",
  "privacyAgreement7": "2. 通知本公司的产品、服务或活动，让用户能及时的掌握交易所最新的情况。",
  "privacyAgreement8": "3. 为扩大公司产品或服务的范围或提高其质量等进行营销、调查或分析，争取让交易所的功能更加完善。",
  "privacyAgreement9": "4、为公司的服务提供维护或支持，及时的收集用户意见和回馈。",
  "privacyAgreement10": "5. 将与所提供服务相关的使用条款、政策等（“条款”）的任何修改通知公司。",
  "privacyAgreement11": "6、处理违反公司服务条款的行为，保护交易所和用户对等的合法权益。",
  "privacyAgreement12": "7、验证用户在金融机构的账户，提供更加安全的资金保护。",
  "privacyAgreement13": "8. 用户在金融机构持有的账户，确保是个人行为，验证使用情况。",
  "privacyAgreement14": "9.紧急情况下的沟通，能够及时的取得联系。",
  "privacyAgreement15": "10.与上述用途有关的任何其他用途。",
  "privacyAgreement16": "三. 限制使用",
  "privacyAgreement17": "未经注册用户同意，本公司不得超出使用目的所要求的范围使用个人信息，但本法或其他法律允许的除外；但规定不适用以下情况：",
  "privacyAgreement18": "1、必须依规使用个人信息在交易所注册和登记；",
  "privacyAgreement19": "2. 使用个人信息为保护个人生命、身体或财产所必需的，且需获得相关客户同意的；",
  "privacyAgreement20": "3.个人信息的使用对改善公众健康或促进儿童身心健康非常必要，且获得相关客户同意；",
  "privacyAgreement21": "4. 国家政府、个人信息的使用或受委托执行法律规定的个人或单位所要求的，征得相关客户同意可能会影响相关事务的执行。",
  "privacyAgreement22": "5.个人交易所财产保护或者被迫限制，需要使用个人信息进行认证的。",
  "privacyAgreement23": "四.安全控制",
  "privacyAgreement24": "公司员工进行全面、适当的监督，以确保个人信息得到控制，以防止丢失、破坏、篡改或泄露安全的漏洞。当本公司委托全部处理或部分个人信息时，本公司对参与进行实时、全面的监督，以确保个人信息的安全控制。",
  "privacyAgreement25": "五.提供给",
  "privacyAgreement26": "除非法律法规允许披露，其他相关客户法律同意，我们才会向政府或者相关机构提供用户个人信息，但绝对不会向个人提供；正常情况下，原则上不向任何的机构或者公司，政府个别部门，或者组织团体提供个人信息：",
  "privacyAgreement27": "1、本公司在为达到使用所需要的范围内委托目的处理个人信息；",
  "privacyAgreement28": "2.因合并或其他活动中的业务变化而提供个人信息。",
  "privacyAgreement29": "3.涉及资金安全问题时需要采集或者配合调查信息时。",
  "privacyAgreement30": "4.资金金额巨大需要调查明确的去向时。",
  "privacyAgreement31": "六.披露",
  "privacyAgreement32": "有其它机构，团体，政府部门，金融机构等如果根据该法律的规定要求披露个人信息，应首先确认该要求是由用户本人确认的，才可以进行提供（如果交易所没有内情信息，应将相关情况通知给客户）；但是，根据该法律或其他法律法规，交易所没有披露个人信息的义务，本规定不适用。",
  "privacyAgreement33": "七.更正等",
  "privacyAgreement34": "客户根据本法规定，以个人信息与事实不符为由，要求本公司更正、添加或删除个人信息，本公司应首先确认该请求是由注册用户本人提出的。然后立即提出请求以达到使用目的。在范围内进行必要的调查，然后根据调查结果更正、添加或删除个人信息，通知相关情况通知客户（当交易所决定不进行更正、添加或删除时交易所应及时通知客户相关情况）；但是，根据本法或其他法律法规的规定，本公司没有义务进行更正、添加或删除时，或者信息验证无法正确匹配时，不予更正。",
  "privacyAgreement35": "八.停止使用等",
  "privacyAgreement36": "如果用户以交易所服务或者其他原因不满意为理由，要求我们停止使用或要求删除个人信息时，必须经调查证明核实。若是出于处理目的或出于其他原因，根据本法规定的获取个人信息，经过核实证明以后，如要求合理，我们会首先确认要求由负责人提出法律要求，然后立即停止使用或删除个人信息，通知相关情况通知客户；但是，公司没有义务执行其它法律或根据其他规定去停止或删除个人信息。当个人信息已经被停止使用或删除时，此规则不适用。",
  "privacyAgreement37": "九、使用信息记录程序和其他技术",
  "privacyAgreement38": "本公司提供的服务可能使用信息记录程序或类似技术。这些技术程序会根据交易所服务的使用情况等，进行更新和升级。设置来使用信息记录程序。请注意，提高信息记录程序后，用户也许将无法再使用部分的服务或者功能。出现此类情况，请立即联系我们，我们会尽快解决。",
  "privacyAgreement39": "（MQG Capital）法务部",
}