
import { Toast } from 'vant';


export const message  = function (msg, type = 'text', icon = '') {
  Toast({
    type,
    message: msg,
    icon
  })
}